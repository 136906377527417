@charset "utf-8";
/*------------------------------------------------------------
	reset
------------------------------------------------------------*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  background: transparent;
  border: 0;
  outline: 0;

  font-size: 1em;
}

body {
  font-size: 62.5%;
}

body,
table,
input,
textarea,
select,
option {
  font-family: sans-serif;
}

article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

img {
  vertical-align: top;
}

a,
a:link {
  color: #666;
  text-decoration: none;
}

a:visited {
  color: #666;
}

a:hover {
  color: #666;
}

a:active {
  color: #666;
}

/*------------------------------------------------------------
	layout
------------------------------------------------------------*/
body {
  min-width: 1100px;
  color: #666;
  font-size: 14px;
  line-height: 1.5;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  background-color: #fff;
}

#container {
  text-align: left;
}

#main {
  margin-bottom: 225px;
}

a[href^='tel:'] {
  cursor: default;
  pointer-events: none;
}

.sp {
  display: none !important;
}

@media all and (min-width: 0) and (max-width: 767px) {
  body {
    min-width: inherit;
  }

  a:hover,
  a:hover img {
    opacity: 1 !important;
  }

  .sp {
    display: block !important;
  }

  .pc {
    display: none !important;
  }

  a[href^='tel:'] {
    cursor: pointer;
    pointer-events: auto;
  }

  #main {
    margin-bottom: 0;
  }
}

/*------------------------------------------------------------
	header
------------------------------------------------------------*/
#gHeader {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  right: 0;
  transition: background-color 0.3s ease, padding 0.3s ease;
}

#gHeader h1 {
  position: fixed;
  top: 36px;
  left: 40px;
  transition: top 0.6s ease;
}

#gHeader h1 a {
  display: block;
}

#gHeader h1 img {
  width: 160px;
  display: block;
  transition: width 0.6s ease;
}

#gHeader .menu {
  width: 35px;
  height: 29px;
  float: right;
  display: block;
  cursor: pointer;
  position: absolute;
  right: 40px;
  top: 30px;
  text-align: center;
  transition: top 0.6s ease;
  z-index: 101;
  background-color: transparent;
}

#gHeader .menu span {
  background: #fc3637 none repeat scroll 0 0;
  border: medium none;
  cursor: pointer;
  height: 1px;
  left: 0;
  position: absolute;
  top: 10px;
  width: 35px;
  transform-origin: center;
  transition: transform 0.35s ease;
}

#gHeader .menu span:nth-of-type(2) {
  top: 18px;
}

#gHeader .menu span:nth-of-type(3) {
  top: 26px;
}

#gHeader .menuBox {
  position: fixed;
  right: -50%;
  top: 0;
  width: 50%;
  height: 100%;
  background: rgba(251, 86, 84, 0.8);
  z-index: 5;
}

.loaded #gHeader .menuBox {
  transition: right 0.8s ease;
}

.menuOpen #gHeader .menu span:nth-of-type(1) {
  transform: translateY(4px) rotateZ(45deg);
}

.menuOpen #gHeader .menu span:nth-of-type(2) {
  transform: translateY(-4px) rotateZ(-45deg);
}
.menuOpen #gHeader .menu span:nth-of-type(3) {
  display: none;
}

.menuOpen #gHeader .menuBox {
  right: 0;
}

.menuBox .subMenu {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.menuBox .subMenu .menuUl01 {
  min-width: 388px;
}

.menuBox .subMenu .menuUl01 > li {
  float: left;
  width: 155px;
  letter-spacing: 2px;
}

.menuBox .subMenu .menuUl01 > li:nth-child(2) {
  float: right;
  width: 155px;
}

.menuBox .subMenu .menuUl01 > li a {
  color: #666;
  font-size: 11px;
  font-weight: lighter;
  transition: color 0.6s ease;
  display: block;
}

.menuBox .subMenu .menuUl01 > li span {
  margin-bottom: -4px;
  color: #fff;
  display: block;
  font-size: 24px;
  float: left;
  letter-spacing: 0.8px;
  transition: color 0.6s ease;
}

.menuBox .subMenu .menuUl01 > li i {
  color: #fff;
  float: right;
  font-style: normal;
  font-size: 24px;
  letter-spacing: 0.8px;
  transition: color 0.6s ease;
}

.menuBox .subMenu .menuUl01 > li a:hover,
.menuBox .subMenu .menuUl01 > li a:hover i,
.menuBox .subMenu .menuUl01 > li a:hover span {
  color: #999;
}

.menuBox .subMenu .menuUl01 > li li {
  margin-top: 53px;
  height: 50px;
}

.menuBox .subMenu .menuUl01 > li li:first-child {
  margin: 0;
}

.menuBox .subMenu .menuUl02 {
  margin-top: 71px;
  font-weight: bold;
  font-size: 0;
  text-align: center;
}

.menuBox .subMenu .menuUl02 p {
  margin-bottom: 14px;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  letter-spacing: 1px;
}

.menuBox .subMenu .menuUl02 li {
  font-size: 15px;
  display: inline-block;
  letter-spacing: 2px;
  width: 25%;
  text-align: left;
  position: relative;
}

.menuBox .subMenu .menuUl02 li:nth-child(2) {
  width: 50%;
  text-align: center;
}

.menuBox .subMenu .menuUl02 li:nth-child(3) {
  width: 25%;
  text-align: right;
}

.menuBox .subMenu .menuUl02 li a {
  padding-left: 20px;
  position: relative;
  transition: color 0.6s ease;
}

.menuBox .subMenu .menuUl02 li a:before {
  width: 10px;
  height: 1px;
  position: absolute;
  top: 8px;
  left: 4px;
  content: '';
  background: #777777;
}

.menuBox .subMenu .menuUl02 li a:hover {
  color: #999;
}

@media all and (min-width: 0) and (max-width: 767px) {
  #gHeader {
    padding: 24px 20px;
    transition: padding 0.6s ease, background 0.6s ease;
  }

  .scrollHeader #gHeader {
    padding: 9px 20px;
    background: #fff;
  }

  #gHeader h1 {
    padding-top: 6px;
    position: static;
    float: left;
  }

  #gHeader h1 img {
    transition: width 0.6s ease;
  }

  .scrollHeader #gHeader h1 img {
    width: 132px;
  }

  #gHeader .menu {
    position: relative;
    top: auto;
    right: auto;
    float: right;
  }

  #gHeader .menuBox {
    right: 0;
    width: 100%;
    overflow-y: scroll;
    opacity: 0;
    visibility: hidden;
    background-color: transparent;
    transition: opacity 0.8s ease, background-color 0.8s ease,
      visibility 0.8s ease;
  }

  .menuOpen #gHeader .menuBox {
    left: 0;
    opacity: 1;
    visibility: visible;
    background-color: rgba(204, 84, 84, 0.95);
    transition: opacity 0s ease, background-color 0.8s ease,
      visibility 0.8s ease;
  }

  .menuBox .close {
    top: 24px;
    right: 20px;
  }

  .menuBox .subMenu .menuUl01 {
    min-width: 340px;
  }

  .menuBox .subMenu .menuUl01 > li {
    width: 160px;
    letter-spacing: 2px;
  }

  .menuBox .subMenu .menuUl01 > li span {
    margin-bottom: 0;
    font-size: 18px;
    letter-spacing: 0.5px;
  }

  .menuBox .subMenu .menuUl01 > li li {
    margin-top: 35px;
    opacity: 0;
  }

  .menuBox .subMenu .menuUl02 {
    margin-top: 56px;
    opacity: 0;
  }

  .menuBox .subMenu .menuUl02 li {
    letter-spacing: 0.6px;
    font-size: 13px;
  }

  .menuBox .subMenu .menuUl02 p {
    font-size: 15px;
  }

  .menuBox .subMenu .menuUl01 > li a:hover {
    color: #666;
  }

  .menuBox .subMenu .menuUl01 > li a:hover span {
    color: #000;
  }

  .menuBox .subMenu .menuUl02 li a:hover {
    color: #000;
  }

  .menuBox .subMenu .menuUl02 li a {
    padding-left: 14px;
  }

  .menuBox .subMenu .menuUl02 li a::before {
    width: 8px;
    left: 2px;
  }
}

/*------------------------------------------------------------
	footer
------------------------------------------------------------*/
#gFooter {
  position: relative;
}

#gFooter .fTtl {
  margin-bottom: 46px;
  text-align: center;
}

#gFooter .fTtl embed {
  width: 165px;
}

#gFooter .fNavi li {
  float: left;
  width: 33.33%;
}

#gFooter .fNavi li a {
  padding: 94px 0 94px;
  display: block;
  color: #fff;
  font-size: 21px;
  letter-spacing: 2px;
  text-align: center;
  background-color: #000;
  border-left: 1px solid #cbcbcb;
  transition: background 0.6s ease;
}

#gFooter .fNavi li:nth-child(2) a {
  letter-spacing: 5px;
}

#gFooter .fNavi li:first-child a {
  border-left: none;
}

#gFooter .fNavi li a:hover {
  background-color: #666666;
}

#gFooter .fBg {
  height: 500px;
  display: none;
  background: url(../../img/common/f_bg.jpg) no-repeat center center;
  background-size: cover;
}

#gFooter .footBottom {
  margin: 0 auto 0;
  max-width: 1086px;
  font-weight: lighter;
}

#gFooter .site {
  font-size: 10px;
  float: left;
  display: block;
  letter-spacing: 0.9px;
  transition: opacity 0.3s ease;
}

#gFooter .qrcode {
  width: 18%;
  margin: 0 auto;
  text-align: center;
  img {
    width: 100%;
  }
  p {
    line-height: 30px;
  }
}

#gFooter .about {
  width: 70%;
  margin: 50px auto 0;
  .contacts {
    float: left;
    margin-top: 25px;
    .contact {
      float: left;
      width: 32%;
      text-align: center;
      position: relative;
      cursor: pointer;
      border-right: solid 2px #ff332e;
      .tip {
        border: solid 1px #ccc;
        padding: 4px;
        position: absolute;
        background: #fff;
        bottom: 150%;
        left: 50%;
        display: none;
        transform: translateX(-50%);
        width: 140%;
        height: auto;
        img {
          width: 100%;
        }
        div {
          background: #ccc;
          line-height: 30px;
          height: 30px;
          display: none;
        }
      }
      span {
        padding-left: 10px;
      }
      img {
        width: 50%;
      }
    }
    .douyin {
      img {
        width: 38%;
      }
      border-right: 0px;
    }
  }

  .address {
    float: right;
    p {
      line-height: 30px;
    }
  }
  .title {
    font-size: 34px;
    line-height: 80px;
    border-bottom: solid 2px #fc3737;
  }
}
#gFooter .site img {
  margin-left: 6px;
  width: 11px;
  vertical-align: -1px;
}

#gFooter .site:hover {
  opacity: 0.6;
}

#gFooter .copyright {
  margin-top: 50px;
  text-align: center;
  font-size: 14px;
  color: #999;
  font-weight: lighter;
  letter-spacing: 1px;
}

#gFooter .pageTop {
  position: absolute;
  left: 50%;
  bottom: 38px;
  transform: translateX(-50%);
  text-align: center;
}

#gFooter .pageTop a:hover {
  opacity: 0.4;
}

#gFooter .pageTop embed {
  width: 27px;
  height: 15px;
  display: block;
}

#gFooter .pageTop p {
  display: inline-block;
}

#gFooter .pageTop a {
  display: block;
  position: relative;
  transition: opacity 0.3s ease;
}

#gFooter .pageTop a:after {
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  background: #fff;
  z-index: 10;
}

.footLink {
  margin-top: 50px;
  text-align: center;
}

.footLink li {
  display: inline-block;
}

.footLink li li {
  padding: 0 9px;
}

.footLink li a {
  padding: 5px 10px;
  display: block;
  font-size: 17px;
  font-style: italic;
  letter-spacing: 0.8px;
  transition: color 0.6s ease;
}

.footLink li a:hover {
  color: #999;
}

.footLink li.curr a {
  color: #999 !important;
}

#cover {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  transition: opacity 0.8s ease, visibility 0.8s ease;
}

.hideCover #cover {
  opacity: 0;
  visibility: hidden;
}

@media all and (min-width: 0) and (max-width: 767px) {
  #gFooter {
    padding-bottom: 23px;
  }

  #gFooter .fTtl {
    margin-bottom: 30px;
  }

  #gFooter .fTtl embed {
    width: 142px;
  }

  #gFooter .fNavi li {
    float: none;
    width: auto;
  }

  #gFooter .fNavi li a:hover,
  #gFooter .fNavi li a {
    padding: 34px 0;
    color: #fff;
    font-size: 21px;
    background-color: #000;
    border-top: 1px solid #cbcbcb;
    border-left: none;
  }

  #gFooter .fNavi li:nth-child(1) a {
    border: none;
  }

  #gFooter .fBg {
    height: 225px;
    background: url(../../img/common/sp_f_bg.jpg) no-repeat center center;
    background-size: cover;
  }

  #gFooter .pageTop {
    position: static;
    margin: 22px 0 10px;
    text-align: center;
    transform: none;
  }

  #gFooter .pageTop embed {
    margin: 0 auto;
  }

  #gFooter .copyright {
    margin-top: 0;
    max-width: inherit;
    text-align: center;
    font-size: 12px;
  }

  .footLink {
    margin-top: 35px;
    padding-left: 10.5%;
    text-align: left;
  }

  .footLink li {
    display: block;
  }

  .footLink > li {
    width: 59%;
    display: block;
    float: left;
    box-sizing: border-box;
  }

  .footLink > li:nth-child(2n) {
    width: auto;
    max-width: 41%;
  }

  .footLink > li li {
    padding: 0;
    margin-bottom: 10px;
  }

  .footLink li a {
    font-size: 18px;
    letter-spacing: 0.7px;
  }

  .footLink li a:hover {
    color: #000;
  }
  #gFooter .about {
    margin-top: 30px;
  }

  #gFooter .about .contact .tip div {
    display: block !important;
  }
  #gFooter .about .contacts .weibo .tip {
    left: 150%;
  }
  #gFooter .about .contacts .douyin .tip {
    left: -50%;
  }
  #gFooter .about .address {
    float: none;
  }
  #gFooter .footBottom {
    margin-top: 30px;
    text-align: center;
  }

  #gFooter .site {
    padding: 5px 0;
    margin-bottom: 6px;
    float: none;
    display: inline-block;
  }

  #cover {
    display: none !important;
  }

  .iphone5 .footLink {
    padding-left: 5%;
  }
}
